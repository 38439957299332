<template>
  <div>
    <div class="flex mb-4 justify-between">
      <h1 class="font-medium text-3xl">{{ $t("inspections") }}</h1>
      <div class="flex-1"></div>
      <button
        v-if="role == 100 || role == 10"
        @click="open = true"
        class="bg-green-500 px-3 rounded text-gray-200"
      >
        <i class="el-icon-plus"></i> {{ $t("add") }}
      </button>
      <download-excel
        name="SYSTEM"
        :fields="json_fields"
        :fetch="DownloadExcel"
        :before-generate="beforeGenerate"
        :before-finish="beforeFinish"
        class="cursor-pointer ml-2 flex items-center border border-black hover:bg-black hover:text-white rounded px-3 py-1"
      >
        <i v-show="!loadingExcel" class="el-icon-download mr-1"></i>
        <i v-show="loadingExcel" class="el-icon-loading mr-1"></i>
        Excel
      </download-excel>
    </div>
    <div class="bg-white mb-2 p-4 rounded border border-gray-300">
      <div class="flex flex-wrap items-center -m-1">
        <div class="p-1 w-1/3 lg:w-1/4 3xl:w-1/6">
          <el-select
            class="w-full"
            v-model="form.train"
            filterable
            clearable
            :placeholder="$t('select_train')"
          >
            <el-option
              v-for="train in TRAINS"
              :key="train.id"
              :label="train.name"
              :value="train.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="p-1 w-1/3 lg:w-1/4 3xl:w-1/6">
          <el-select
            class="w-full"
            v-model="form.sample__corrective_type"
            filterable
            clearable
            :placeholder="$t('corrective_type')"
          >
            <el-option
              v-for="corrective_type in corrective_types"
              :key="corrective_type.id"
              :label="corrective_type.name"
              :value="corrective_type.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="p-1 w-1/3 lg:w-1/4 3xl:w-1/6">
          <el-select
            class="w-full"
            v-model="form.responsible_person"
            :placeholder="$t('select_responsible_person')"
          >
            <el-option
              v-for="responsible_person in RESPONSIBLE_PERSONS"
              :key="responsible_person.id"
              :label="responsible_person.full_name"
              :value="responsible_person.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="p-1 w-1/3 lg:w-1/4 3xl:w-1/6">
          <el-date-picker
            style="width: 100%"
            v-model="form.created_time__gte"
            value-format="yyyy-MM-dd"
            type="date"
            :placeholder="$t('select_begin_date')"
          >
          </el-date-picker>
        </div>
        <div class="p-1 w-1/3 lg:w-1/4 3xl:w-1/6">
          <el-date-picker
            style="width: 100%"
            v-model="form.created_time__lte"
            value-format="yyyy-MM-dd"
            type="date"
            :placeholder="$t('select_end_date')"
          >
          </el-date-picker>
        </div>
        <div v-if="role == 100" class="p-1 w-1/3 lg:w-1/4 3xl:w-1/6">
          <el-select
            class="w-full"
            v-model="form.is_signed"
            :placeholder="$t('status')"
          >
            <el-option :label="$t('finished')" :value="true"> </el-option>
            <el-option :label="$t('not_finished')" :value="false"> </el-option>
          </el-select>
        </div>

        <div class="p-1 flex-1 flex justify-end items-center">
          <el-button size="small" type="warning" @click="clearFilter"
            ><i class="mr-1 el-icon-refresh-right"></i
            >{{ $t("clear") }}</el-button
          >
          <el-button
            size="small"
            type="primary"
            :loading="false"
            @click="getInspections"
            ><i class="mr-1 el-icon-search"></i>{{ $t("search") }}</el-button
          >
        </div>
      </div>
    </div>
    <el-button
      v-if="
        multipleSelection.length &&
        inspectionsList.length &&
        !inspectionsList[0].is_signed
      "
      @click="SignedOpen = true"
      >{{ $t("completion") }}</el-button
    >
    <el-table
      :data="inspectionsList"
      border
      v-loading="loading"
      style="width: 100%"
      class="rounded-lg border shadow-md mt-3"
      @selection-change="handleSelectionChange"
    >
      <el-table-column fixed align="center" type="selection" width="55">
      </el-table-column>
      <el-table-column align="center" prop="id" label="ID" width="60">
      </el-table-column>
      <el-table-column
        :label="$t('train_name')"
        prop="train.name"
        min-width="200"
      >
      </el-table-column>
      <el-table-column
        :label="$t('author')"
        prop="author.full_name"
        min-width="200"
      >
      </el-table-column>
      <el-table-column :label="$t('order')" prop="order" min-width="160">
      </el-table-column>

      <el-table-column :label="$t('Plan_code')" prop="code" min-width="120">
      </el-table-column>
      <el-table-column
        :label="$t('register_number')"
        prop="number"
        min-width="160"
      >
      </el-table-column>
      <el-table-column :label="$t('sample')" prop="sample.name" min-width="190">
      </el-table-column>
      <el-table-column
        :label="$t('responsible_person')"
        prop="responsible_person.full_name"
        min-width="200"
      >
      </el-table-column>
      <el-table-column :label="$t('workers')" min-width="200">
        <template slot-scope="scope">
          <div v-for="worker in scope.row.workers" :key="worker.id">
            {{ worker.full_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column :label="$t('comment')" prop="comment" min-width="200">
      </el-table-column>
      <el-table-column
        prop="created_time"
        :label="$t('created_time')"
        min-width="180"
      >
      </el-table-column>
      <el-table-column :label="$t('finished')" min-width="250">
        <template slot-scope="props">
          <div v-if="props.row.is_signed">
            <p>{{ props.row.author.full_name }}</p>
            <p>{{ props.row.last_updated_time }}</p>
          </div>
          <div v-else>
            {{ $t("not_finished") }}
          </div>
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        :label="$t('actions')"
        min-width="280"
        v-if="role == 100 || role == 10"
      >
        <template slot-scope="scope">
          <el-button
            icon="el-icon-tickets"
            size="medium"
            @click="openLog(scope.row.id)"
          ></el-button>
          <router-link :to="`/inspections/${scope.row.id}`" class="mx-2">
            <el-button size="medium" icon="el-icon-view"> </el-button>
          </router-link>
          <el-button
            :disabled="scope.row.is_signed"
            size="medium"
            icon="el-icon-edit"
            @click="openEdit(scope.row.id)"
          >
          </el-button>
          <el-popconfirm
            :confirm-button-text="$t('yes')"
            :cancel-button-text="$t('no_thanks')"
            icon="el-icon-info"
            icon-color="red"
            :title="$t('you_want_to_delete')"
            class="ml-2"
            @confirm="InspectionDelete(scope.row.id)"
          >
            <el-button
              slot="reference"
              icon="el-icon-delete"
              size="medium"
              type="danger"
              :disabled="
                (role == 10 && scope.row.author.role == 100) ||
                scope.row.is_signed
              "
            >
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
      <el-table-column fixed="right" :label="$t('actions')" width="90" v-else>
        <template slot-scope="scope">
          <router-link :to="`/inspections/${scope.row.id}`" class="mr-2">
            <el-button size="medium" icon="el-icon-view"> </el-button>
          </router-link> </template
      ></el-table-column>
    </el-table>
    <el-dialog
      :title="$t('done')"
      class="log_dialog"
      :visible.sync="SignedOpen"
    >
      <el-table
        :data="multipleSelection"
        border
        style="width: 100%"
        class="rounded border shadow-md mt-3"
      >
        <el-table-column
          :label="$t('register_number')"
          prop="number"
          min-width="160"
        >
        </el-table-column>
        <el-table-column
          :label="$t('sample')"
          prop="sample.name"
          min-width="120"
        >
        </el-table-column>
        <el-table-column
          :label="$t('responsible_person')"
          prop="responsible_person.full_name"
          min-width="200"
        >
        </el-table-column>
        <el-table-column
          prop="last_updated_time"
          :label="$t('last_updated_time')"
          min-width="250"
        >
        </el-table-column>
      </el-table>
      <div class="mt-4 flex justify-end">
        <el-button @click="SignedOpen = false">{{ $t("cancel") }}</el-button>
        <el-button @click="InspectionsSigned">{{ $t("acceptance") }}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('logs')" class="log_dialog" :visible.sync="logDialog">
      <el-table :data="inspection_logs" border v-loading="log_loading">
        <el-table-column
          property="user"
          :label="$t('user')"
          min-width="150"
        ></el-table-column>
        <el-table-column
          property="text"
          :label="$t('text')"
          min-width="300"
        ></el-table-column>
        <el-table-column
          property="created_time"
          :label="$t('created_time')"
          width="180"
        ></el-table-column>
      </el-table>
    </el-dialog>
    <el-drawer
      :visible.sync="open"
      :with-header="false"
      size="400px"
      @close="CloseDrawer"
      v-if="role == 100 || role == 10"
    >
      <div
        class="h-20 bg-111E52 flex items-center justify-between px-3 text-white text-xl"
      >
        <p>SYSTEM</p>
        <button @click="open = false" class="px-2">
          <i class="el-icon-close"></i>
        </button>
      </div>
      <div class="p-4">
        <h1 class="mb-4">{{ $t("add_inspections") }}</h1>
        <el-form
          ref="inspection"
          :rules="rules"
          label-position="top"
          :model="inspection"
        >
          <el-form-item
            prop="train"
            :rules="{
              required: true,
              message: $t('please_select'),
              trigger: 'blur',
            }"
            style="margin-bottom: 10px"
            :label="$t('train')"
          >
            <el-select
              :disabled="edit && role == 10 && inspection.author != AUTHOR"
              v-model="inspection.train"
              :placeholder="$t('select')"
              class="w-full"
              filterable
            >
              <el-option
                v-for="train in TRAINS"
                :key="train.id"
                :label="train.name"
                :value="train.id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            prop="order"
            style="margin-bottom: 10px"
            :label="$t('order')"
          >
            <el-input
              :disabled="edit && role == 10 && inspection.author != AUTHOR"
              :placeholder="$t('input')"
              v-model.number="inspection.order"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 10px" :label="$t('Plan_code')">
            <el-input
              :placeholder="$t('input')"
              v-model.number="inspection.code"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item
            :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }"
            prop="number"
            style="margin-bottom: 10px"
            :label="$t('register_number')"
          >
            <el-input
              :disabled="edit && role == 10 && inspection.author != AUTHOR"
              :placeholder="$t('input')"
              v-model="inspection.number"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item
            :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }"
            prop="sample"
            style="margin-bottom: 10px"
            :label="$t('sample')"
          >
            <el-select
              :disabled="edit && role == 10 && inspection.author != AUTHOR"
              v-model="inspection.sample"
              :placeholder="$t('select')"
              class="w-full"
            >
              <el-option
                v-for="sample in samples"
                :key="sample.id"
                :label="sample.name"
                :value="sample.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="role == 100"
            :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }"
            prop="responsible_person"
            style="margin-bottom: 10px"
            :label="$t('responsible_person')"
          >
            <el-select
              :disabled="edit && role == 10 && inspection.author != AUTHOR"
              v-model="inspection.responsible_person"
              :placeholder="$t('select')"
              class="w-full"
              filterable
            >
              <el-option
                v-for="responsible_person in RESPONSIBLE_PERSONS"
                :key="responsible_person.id"
                :label="responsible_person.full_name"
                :value="responsible_person.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            style="margin-bottom: 10px"
            :label="$t('workers')"
            v-if="role == 10"
          >
            <el-select
              v-model="inspection.workers"
              :placeholder="$t('select')"
              class="w-full"
              multiple
              filterable
            >
              <el-option
                v-for="worker in WORKERS"
                :key="worker.id"
                :label="worker.full_name"
                :value="worker.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            :rules="{
              required: true,
              message: $t('please_input'),
              trigger: 'blur',
            }"
            prop="comment"
            style="margin-bottom: 10px"
            :label="$t('comment')"
          >
            <el-input
              :disabled="edit && role == 10 && inspection.author != AUTHOR"
              :placeholder="$t('input')"
              v-model="inspection.comment"
              clearable
              type="textarea"
              :rows="4"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="text-right">
          <el-button
            v-if="!edit"
            @click="addInspection"
            type="primary"
            :loading="loading"
          >
            {{ $t("add") }}
          </el-button>
          <el-button
            v-else
            @click="editInspection"
            type="primary"
            :loading="loading"
          >
            {{ $t("save") }}
          </el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Inspections",
  data() {
    var checkNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("please_input")));
      }
      if (!Number.isInteger(value)) {
        callback(new Error(this.$t("please_input_digits")));
      } else {
        callback();
      }
    };
    return {
      SignedOpen: false,
      multipleSelection: [],
      loadingExcel: false,
      time: [],
      workers: [],
      status: [],
      form: {
        is_signed: false,
        sample__corrective_type: "",
        train: "",
        created_time__gte: "",
        created_time__lte: "",
        responsible_person: "",
        status: "",
      },
      logDialog: false,
      log_loading: false,
      inspection_logs: [],
      open: false,
      loading: false,
      totalCount: 0,
      count: 1,
      edit: false,
      editId: "",
      edit_inspection: "",
      inspectionsList: [],
      SAMPLES: [],
      TRAINS: [],
      corrective_types: [],
      RESPONSIBLE_PERSONS: "",
      WORKERS: "",
      inspection: {
        train: "",
        author: "",
        order: "",
        code: "",
        number: "",
        sample: "",
        responsible_person: "",
        workers: "",
        comment: "",
      },
      rules: {
        order: [
          {
            validator: checkNumber,
            trigger: "blur",
          },
        ],
      },
      role: localStorage.role,
      AUTHOR: localStorage.id,
    };
  },
  mounted() {
    this.getInspections();
    this.getSelects();
  },
  computed: {
    samples() {
      if (this.role == 100) {
        return this.SAMPLES;
      }
      return this.SAMPLES.filter((data) => data.type == 9);
    },
    json_fields() {
      let fields = "";
      this.$i18n.locale === "ru"
        ? (fields = {
            ID: "id",
            "Название поезда": "train",
            Автор: "author",
            Приказ: "order",
            "Код плана": "code",
            "№ Номер регистра": "number",
            "Ответственный человек": "responsible_person",
            Рабочие: "workers",
            "Созданное время": "created_time",
            "Последнее обновление время": "last_updated_time",
          })
        : (fields = {
            ID: "id",
            "Poyezd nomi": "train",
            Aftor: "author",
            Buyurtma: "order",
            "Reja kodi": "code",
            "№ Registr raqami": "number",
            "Mas'ul shaxs": "responsible_person",
            Iahchilar: "workers",
            "Yaratilgan vaqti": "created_time",
            "Oxirgi o'zgartirilgan vaqt": "last_updated_time",
          });
      return fields;
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    InspectionsSigned() {
      this.multipleSelection.forEach((data, index) => {
        axios.patch(`inspections/${data.id}/`, { is_signed: true }).then(() => {
          if (index === this.multipleSelection.length - 1) {
            this.getInspections();
            this.SignedOpen = false;
            this.multipleSelection = "";
          }
        });
      });
    },
    async DownloadExcel() {
      let filter = "";
      Object.keys(this.form).forEach((key) => {
        if (this.form[key]) {
          filter += "&" + key + "=" + this.form[key];
        }
      });
      const response = await axios.get(`inspections/?no_page&${filter}`);
      if (response.data.length) {
        let data = response.data.map((data) => {
          data.author = data.author.full_name;
          data.responsible_person = data.responsible_person.full_name;
          data.sample = data.sample.name;
          data.train = data.train.name;
          let workers = "";
          data.workers.forEach((key) => {
            workers += key.full_name + ", ";
          });
          data.workers = workers;
          data.created_time = data.created_time
            .split(".")
            .shift()
            .replace("T", " ");
          data.last_updated_time = data.last_updated_time
            .split(".")
            .shift()
            .replace("T", " ");
          return data;
        });
        return data;
      } else {
        return [{}];
      }
    },
    beforeGenerate() {
      this.loadingExcel = true;
    },
    beforeFinish() {
      this.loadingExcel = false;
    },
    clearFilter() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = "";
      });
    },
    openLog(id) {
      this.logDialog = true;
      this.log_loading = true;
      axios
        .get(`inspections/${id}/logs/`)
        .then((response) => {
          this.log_loading = false;
          this.inspection_logs = response.data.map((data) => {
            return {
              ...data,
              created_time: data.created_time
                .split(".")
                .shift()
                .replace("T", " "),
            };
          });
        })
        .catch(() => {
          this.log_loading = false;
        });
    },
    async getInspections() {
      this.loading = true;
      let filter = "";
      Object.keys(this.form).forEach((key) => {
        if (this.form[key] || this.form[key] === false) {
          filter += "&" + key + "=" + this.form[key];
        }
      });
      await axios
        .get(`inspections/?page=${this.count}${filter}`)
        .then((response) => {
          this.totalCount = response.data.count;
          this.inspectionsList = response.data.results.map((data) => {
            return {
              ...data,
              created_time: data.created_time
                .split(".")
                .shift()
                .replace("T", " "),
              last_updated_time: data.last_updated_time
                .split(".")
                .shift()
                .replace("T", " "),
            };
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    Prev() {
      this.count--;
      this.getInspections();
    },
    handleCurrentChange(val) {
      this.count = val;
      this.getInspections();
    },
    Next() {
      this.count++;
      this.getInspections();
    },
    CloseDrawer() {
      this.edit = false;
      Object.keys(this.inspection).forEach((key) => {
        this.inspection[key] = "";
      });
      this.$refs.inspection.clearValidate();
    },
    async getSelects() {
      await axios.get(`samples/?no_page`).then((response) => {
        this.SAMPLES = response.data;
      });
      await axios.get(`trains/?no_page`).then((response) => {
        this.TRAINS = response.data;
      });
      await axios.get(`corrective-types/?no_page`).then((response) => {
        this.corrective_types = response.data;
      });
      await axios.get(`users/?no_page`).then((response) => {
        this.RESPONSIBLE_PERSONS = response.data.filter(
          (data) => data.role == 10
        );
        this.WORKERS = response.data.filter((data) => data.role == 9);
      });
    },
    async addInspection() {
      this.inspection.author = localStorage.getItem("id");
      if (this.role == 10) {
        this.inspection.responsible_person = localStorage.id;
      }
      this.inspection.workers ? "" : (this.inspection.workers = []);
      this.$refs.inspection.validate((valid) => {
        if (valid) {
          this.loading = true;
          axios
            .post("inspections/", this.inspection)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getInspections();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_added"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("no_data_added"),
              });
            });
        }
      });
    },
    openEdit(id) {
      this.open = true;
      this.edit = true;
      this.editId = id;
      this.edit_inspection = this.inspectionsList.find(
        (data) => data.id === id
      );
      Object.keys(this.inspection).forEach((key) => {
        if (key === "workers") {
          this.inspection[key] = this.edit_inspection[key].map(({ id }) => id);
        } else {
          if (typeof this.edit_inspection[key] === "object") {
            this.inspection[key] = this.edit_inspection[key].id;
          } else {
            this.inspection[key] = this.edit_inspection[key];
          }
        }
      });
    },
    async InspectionDelete(id) {
      this.loading = true;
      await axios
        .delete(`inspections/${id}/`)
        .then(() => {
          this.totalCount % 10 === 1 && this.totalCount / 10 >= 1
            ? this.count--
            : "";
          this.loading = false;
          this.getInspections();
          this.$notify({
            title: this.$t("success"),
            message: this.$t("data_deleted"),
            type: "success",
          });
        })
        .catch(() => {
          this.loading = false;
          this.$notify.error({
            title: this.$t("error"),
            message: this.$t("data_not_deleted"),
          });
        });
    },
    editInspection() {
      if (this.role == 10) {
        this.inspection.responsible_person = localStorage.id;
      }
      this.$refs.inspection.validate((valid) => {
        if (valid) {
          this.loading = true;
          axios
            .patch(`inspections/${this.editId}/`, this.inspection)
            .then(() => {
              this.loading = false;
              this.open = false;
              this.getInspections();
              this.$notify({
                title: this.$t("success"),
                message: this.$t("data_changed"),
                type: "success",
              });
            })
            .catch(() => {
              this.loading = false;
              this.open = false;
              this.$notify.error({
                title: this.$t("error"),
                message: this.$t("data_unchanged"),
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
